import React from "react"
import { graphql } from "gatsby"
import { V3UsefulInfoFieldsFragment } from "../../../../../graphql-types"

import * as styles from "./v3-useful-infos.module.scss"
import SimpleText from "../simple-text/simple-text"

type RenderProps = {
  data: V3UsefulInfoFieldsFragment
}

const V3TextCarousel: React.FC<RenderProps> = ({ data }) => (
  <div className={styles.container}>
    <div className={styles.emptyLeftPart} />
    <div className={styles.usefulInfosWrapper}>
      <h2 className={styles.title}>{data.title}</h2>
      <div className={styles.paragraphs}>
        {data.usefulInfoContent.map(item => (
          <SimpleText data={item} key={item.id} />
        ))}
      </div>
    </div>
  </div>
)

export const fragment = graphql`
  fragment V3UsefulInfoFields on DatoCmsV3UsefulInfo {
    __typename
    title
    usefulInfoContent: content {
      ...SimpleTextFields
    }
  }
`

export default V3TextCarousel
