import React, { useEffect, useState } from "react"
import * as styles from "./pagination.module.scss"
import { useLocale } from "../../context/locale-context"

type ArticleSection = { publicationDate: string; articles: any[] }

type RenderProps = {
  articles: ArticleSection[]
  filteredArticles: ArticleSection[]
  onSelect: (articles: ArticleSection[]) => void
}

const Pagination: React.FC<RenderProps> = ({ articles, filteredArticles, onSelect }) => {
  const { locale } = useLocale()

  const translations = {
    fr: { year: "Année", month: "Mois" },
    en: { year: "Year", month: "Month" },
  }

  const { year, month } = translations[locale] || translations.en

  const monthsList = filteredArticles
    .map(article => article.publicationDate.split(" ")[0])
    .filter((value, index, self) => self.indexOf(value) === index)

  const orderedMonth = monthsList.sort((a, b) => {
    const monthNames =
      locale === "fr"
        ? [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
          ]
        : [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ]
    return monthNames.indexOf(a) - monthNames.indexOf(b)
  })

  const yearsList = filteredArticles
    .map(article => article.publicationDate.split(" ")[1])
    .filter((value, index, self) => self.indexOf(value) === index)

  const [selectedMonth, setSelectedMonth] = useState("")
  const [selectedYear, setSelectedYear] = useState(yearsList?.[0] || "")
  const [showMonthOptions, setShowMonthOptions] = useState(false)
  const [showYearOptions, setShowYearOptions] = useState(false)

  useEffect(() => {
    if (yearsList.length > 0) {
      onSelect(filteredArticles.filter(article => article.publicationDate.includes(yearsList[0])))
    }
  }, [])

  const handleYearSelect = (year: string) => {
    setSelectedYear(year)
    setShowYearOptions(false)
    onSelect(
      year
        ? filteredArticles.filter(article => article.publicationDate.includes(year))
        : articles.filter(article => article.publicationDate.includes(selectedMonth))
    )
  }

  const handleMonthSelect = (month: string) => {
    setSelectedMonth(month)
    setShowMonthOptions(false)
    onSelect(
      month
        ? filteredArticles.filter(article => article.publicationDate.includes(month))
        : articles.filter(article => article.publicationDate.includes(selectedYear))
    )
  }

  const resetYear = () => {
    setSelectedYear("")
    setShowYearOptions(false)
    onSelect(articles.filter(article => article.publicationDate.includes(selectedMonth)))
  }

  const resetMonth = () => {
    setSelectedMonth("")
    setShowMonthOptions(false)
    onSelect(articles.filter(article => article.publicationDate.includes(selectedYear)))
  }

  return (
    <div className={styles.pagination}>
      <div className={styles.selectWrapper}>
        <div
          className={`${styles.selectContainer} ${selectedYear ? styles.selected : ""}`}
          onClick={() => setShowYearOptions(!showYearOptions)}
        >
          <div className={`${styles.inputSelect} ${selectedYear ? styles.selected : ""}`}>{selectedYear || year}</div>
          {showYearOptions && (
            <div className={styles.options}>
              <div className={styles.option} onClick={() => handleYearSelect("")}>
                {year}
              </div>
              {yearsList.map(year => (
                <div
                  key={year}
                  className={`${styles.option} ${selectedYear === year ? styles.selected : ""}`}
                  onClick={() => handleYearSelect(year)}
                >
                  {year}
                </div>
              ))}
            </div>
          )}
          {selectedYear && (
            <span
              onClick={e => {
                e.stopPropagation()
                resetYear()
              }}
              className={styles.resetButton}
            ></span>
          )}
        </div>
      </div>
      <div className={styles.selectWrapper}>
        <div
          className={`${styles.selectContainer} ${selectedMonth ? styles.selected : ""}`}
          onClick={() => setShowMonthOptions(!showMonthOptions)}
        >
          <div className={`${styles.inputSelect} ${selectedMonth ? styles.selected : ""}`}>
            {selectedMonth || month}
          </div>
          {showMonthOptions && (
            <div className={styles.options}>
              <div className={styles.option} onClick={() => handleMonthSelect("")}>
                {month}
              </div>
              {orderedMonth.map(month => (
                <div
                  key={month}
                  className={`${styles.option} ${selectedMonth === month ? styles.selected : ""}`}
                  onClick={() => handleMonthSelect(month)}
                >
                  {month}
                </div>
              ))}
            </div>
          )}
          {selectedMonth && (
            <span
              onClick={e => {
                e.stopPropagation()
                resetMonth()
              }}
              className={styles.resetButton}
            ></span>
          )}
        </div>
      </div>
    </div>
  )
}

export default Pagination
