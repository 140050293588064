import React, { useState } from "react"
import { graphql } from "gatsby"
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form"
import { ContactPageFieldsFragment } from "../../../../../graphql-types"
import * as styles from "../contact-page.module.scss"

type RenderProps = {
  formFields: ContactPageFieldsFragment[]
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  emailFieldError: string
  requiredFieldErrorMsg: string
}

const FormInputs: React.FC<RenderProps> = ({
  formFields,
  register,
  errors,
  emailFieldError,
  requiredFieldErrorMsg,
}) => {
  const [focusedElement, setFocusedElement] = useState(null)

  const handleFocus = e => setFocusedElement(e.target.name)
  const handleBlur = () => setFocusedElement(null)

  const generateDropdownField = (field: ContactPageFieldsFragment, selectClass: string) => {
    return (
      <div className={styles.input} key={field.id}>
        <div className={styles.selectContainer}>
          <select
            id={field.name}
            className={selectClass}
            name={field.name}
            aria-label={field.label}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...register(field.name, { required: field.isRequired })}
          >
            <option value="" disabled selected>
              {field.label}
            </option>
            {field.options?.map(option => (
              <option value={option.title} key={option.id}>
                {option.title}
              </option>
            ))}
          </select>
        </div>
        <br />
        {errors[field.name] && <span className={styles.error}>{requiredFieldErrorMsg}</span>}
      </div>
    )
  }

  const generateTextArea = (field: ContactPageFieldsFragment, textareaClass: string) => {
    const label = field.isRequired ? `${field.label} *` : field.label

    return (
      <div className={styles.input} key={field.id}>
        <textarea
          id={field.name}
          className={textareaClass}
          placeholder={label}
          name={field.name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-label={field.label}
          rows={5}
          cols={5}
          {...register(field.name, { required: field.isRequired, maxLength: 300 })}
        />
        <br />
        {errors[field.name] && <span className={styles.error}>{requiredFieldErrorMsg}</span>}
      </div>
    )
  }

  const generateTextField = (field: ContactPageFieldsFragment, inputClass: string) => {
    const label = field.isRequired ? `${field.label} *` : field.label

    return (
      <div className={styles.input} key={field.id}>
        <input
          type={field.isEmail ? "email" : "text"}
          id={field.name}
          name={field.name}
          className={inputClass}
          placeholder={label}
          autoComplete={field.autocomplete}
          aria-label={field.label}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...register(
            field.isEmail ? "email" : field.name,
            field.isEmail
              ? {
                  required: field.isRequired,
                  pattern: {
                    value: /^[A-Z\d._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: emailFieldError,
                  },
                }
              : {
                  required: field.isRequired,
                  maxLength: 90,
                }
          )}
        />
        <br />
        {errors[field.name] && (
          <span className={styles.error}>{field.isEmail ? emailFieldError : requiredFieldErrorMsg}</span>
        )}
      </div>
    )
  }

  return formFields.map(field => {
    const hasError = errors[field.name]
    const inputClass = `${styles.inputText} ${hasError ? styles.inputError : ""} ${focusedElement === field.name ? styles.formTextFieldWithFocus : ""}`
    const selectClass = `${styles.inputSelect} ${hasError ? styles.inputError : ""} ${focusedElement === field.name ? styles.formDropdownFieldWithFocus : styles.formDropdownFieldWithoutFocus}`
    const textareaClass = `${styles.inputTextarea} ${hasError ? styles.inputError : ""} ${focusedElement === field.name ? styles.textareaWithFocus : ""}`

    if (
      field.isDropdownField &&
      field.options.length === 2 &&
      field.options.some(option => ["Oui", "Non"].includes(option.title))
    ) {
      return (
        <div className={styles.input} key={field.id}>
          <label>{field.label}</label>
          <div className={styles.radioContainer}>
            {field.options.map(option => (
              <div className={styles.radioOption} key={`${field.name}-${option.title}`}>
                <input
                  type="radio"
                  name={field.name}
                  value={option.title}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  {...register(field.name, { required: field.isRequired })}
                />
                <label className={styles.labelYesOrNo}>{option.title}</label>
              </div>
            ))}
          </div>
          {errors[field.name] && <span className={styles.error}>{requiredFieldErrorMsg}</span>}
        </div>
      )
    }
    if (field.isDropdownField) {
      return generateDropdownField(field, selectClass)
    } else if (field.isTextarea) {
      return generateTextArea(field, textareaClass)
    } else {
      return generateTextField(field, inputClass)
    }
  })
}

export const fragment = graphql`
  fragment ContactPageFields on DatoCmsContactPageDropdownField {
    id
    name
    autocomplete
    label
    isRequired
    isEmail
    isDropdownField
    isTextarea
    textareaTitle
    options {
      id
      title
    }
  }
`

export default FormInputs
