import React, { useRef } from "react"
import { graphql } from "gatsby"
import { PartnersLargeFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./large-partners.module.scss"
import { useIsMobile } from "../../../hooks/use-is-mobile"

type RenderProps = {
  data: PartnersLargeFieldsFragment
}

const PartnersLarge: React.FC<RenderProps> = ({ data }) => {
  const isMobile = useIsMobile()
  return (
    <div className={styles.container}>
      <div
        className={styles.backgroundImage}
        style={
          {
            "--background-image": data.backgroundImage.url ? `url(${data.backgroundImage.url})` : "",
          } as React.CSSProperties
        }
      >
        <div className={styles.partners}>
          {data.partners.map((partner, index) => {
            if (isMobile && index > 3) {
              return
            }
            const url = partner.url && (partner.url.startsWith("http") ? partner.url : `https://${partner.url}`)

            return url ? (
              <a className={styles.redirectLink} key={index} href={url} target="_blank" rel="noopener noreferrer">
                <img className={styles.partner} src={partner.logo.url} alt={partner.logo.alt} />
              </a>
            ) : (
              <img className={styles.partner} src={partner.logo.url} alt={partner.logo.alt} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment PartnersLargeFields on DatoCmsPartnersLarge {
    __typename
    backgroundImage {
      alt
      format
      title
      url
    }
    partners {
      logo {
        ...PartnerImageFields
      }
      url
    }
  }

  fragment PartnerImageFields on DatoCmsFileField {
    alt
    format
    url
  }
`

export default PartnersLarge
