import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { OutlinedTextBlockFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./outlined-text-block.module.scss"
import { render } from "datocms-structured-text-to-html-string"
import { StructuredText as StructuredTextGraphQlResponse } from "datocms-structured-text-utils/dist/types/types"
import sanitizeHtml from "sanitize-html"
import { useIsMobile } from "../../../hooks"
import linkedinLogo from "../../../../../static/images/icon-linkedin.svg"
import twitterLogo from "../../../../../static/images/icon-twitter.svg"
import { DisplayImage } from "../../../../components/display-image/display-image"

type RenderProps = {
  data: OutlinedTextBlockFieldsFragment
}

const OutlinedTextBlock: React.FC<RenderProps> = ({ data }) => {
  const getColors = bgColor => {
    if (bgColor.hex.toLowerCase() === "#ffffff") {
      return { titleColor: "#0075BE", borderColor: "#0075BE", backgroundColor: "white" }
    }
    return {
      titleColor: bgColor.hex,
      borderColor: bgColor.hex,
      backgroundColor: bgColor.hex,
    }
  }

  const isMobile = useIsMobile()
  const { titleColor, backgroundColor, borderColor } = getColors(data.backgroundColor)
  const shouldDisplayTitle = data.isTitleDisplayed && data.title && data.title.trim()
  const shouldDisplaySocialNetworks = data.areSocialNetworksDisplayed && (data.linkedinLink || data.twitterLink)

  const getSocialNetworkLink = (text, link, logoPath) => (
    <li className={styles.socialNetworkLinkWrapper}>
      <a href={link} className={styles.socialNetworkLink}>
        <ReactSVG src={logoPath} className={styles.socialNetworkLogo} aria-hidden={true} role="presentation" />
        {text}
      </a>
    </li>
  )

  const renderContent = content => {
    const options = {
      renderInlineRecord({ record, adapter: { renderNode } }) {
        return renderNode(
          "a",
          { href: `/${record.slug}` },
          sanitizeHtml(record.title, {
            allowedTags: [],
            allowedAttributes: {},
          })
        )
      },
      renderLinkToRecord({ record, children, adapter: { renderNode } }) {
        return renderNode("a", { href: `/${record.slug}` }, children)
      },
    }
    return render(content as unknown as StructuredTextGraphQlResponse, options)
  }

  return (
    <div
      className={styles.outlinedContainer}
      style={
        {
          "--title-color": titleColor,
          "--background-color": backgroundColor,
          "--border-color": borderColor,
          "--text-color": data.textColor.hex,
        } as React.CSSProperties
      }
    >
      <div className={styles.leftContainer}>
        {shouldDisplayTitle && (
          <h2
            style={
              {
                "--margin-bottom": data.picture ? "5rem" : "2.5rem",
              } as React.CSSProperties
            }
            className={styles.title}
          >
            {data.title}
          </h2>
        )}
      </div>
      <div className={styles.rightContainer}>
        <div
          className={styles.content}
          style={{ marginTop: shouldDisplayTitle || !data.picture ? "" : isMobile ? "45px" : "55px" }}
        >
          {data.picture && (
            <div className={styles.pictureWrapper}>
              <DisplayImage image={data.picture} svgStyle={styles.picture} imgStyle={styles.picture} />
            </div>
          )}
          <div className={styles.contentText} dangerouslySetInnerHTML={{ __html: renderContent(data.content) }} />
          {shouldDisplaySocialNetworks && (
            <ul className={styles.socialNetworks}>
              {data.linkedinLink && getSocialNetworkLink("LinkedIn", data.linkedinLink, linkedinLogo)}
              {data.twitterLink && getSocialNetworkLink("Twitter", data.twitterLink, twitterLogo)}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment OutlinedTextBlockFields on DatoCmsOutlinedTextBlock {
    isTitleDisplayed
    title
    textColor {
      hex
    }
    backgroundColor {
      hex
    }
    picture {
      alt
      url
    }
    content {
      value
      blocks
      links {
        __typename
        ... on DatoCmsPage {
          id: originalId
          slug
        }
        ... on DatoCmsHomePage {
          id: originalId
        }
        ... on DatoCmsNews {
          id: originalId
          slug
        }
        ... on DatoCmsPressRelease {
          id: originalId
          slug
        }
        ... on DatoCmsParentPage {
          id: originalId
          slug
        }
      }
    }
    areSocialNetworksDisplayed
    linkedinLink
    twitterLink
  }
`

export default OutlinedTextBlock
