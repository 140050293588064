const {fr} = require("date-fns/locale");
module.exports = Object.assign({dateFnsLocale: fr}, {
  "chapo_length_number": 559,
  "date_format": {
    "fr": "d MMMM yyyy"
  },
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "notifications_service_endpoint": "https://apim.keolis.com/SIK/000/notification",
  "search_token": "23776838275d54d27db37ffeb99e5b",
  "v3_share_label": {
    "fr": "Je partage"
  },
  "hour_format": {
    "fr": "HH:mm"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/SIK/000/file/import HTTP/1.1",
  "piano_site_id": "639192",
  "site_name": "Keolis Ile-de-France",
  "v3_social_networks": [
    "YQ3JVW-gQgyw3jBiBqALjQ",
    "d3V3mpTAT3WeenHmTD0LsQ",
    "RKhvztQwTpO00dt7QeYtJA",
    "NSwgw--9TceqiYpJxebGeA"
  ],
  "facebook_link": "https://www.facebook.com/sharer/sharer.php?u=",
  "google_tag_manager_id": "GTM-5PWGKJJT",
  "meta_description": {
    "fr": "Keolis est un opérateur privé de transport public de voyageurs, engagé pour une mobilité durable et connectée."
  },
  "notifications_service_token": "2d5392f4a9404b2690515b92a3eadc93",
  "google_analytics_key": "",
  "subsidiary_logo": {
    "fr": {
      "path": "/102759/1624895286-group.png",
      "format": "png",
      "size": 8073,
      "alt": "Keolis Brand",
      "title": "Keolis ",
      "width": 815,
      "height": 126
    }
  },
  "twitter_link": "https://twitter.com/intent/tweet?url=",
  "linkedin_link": "https://www.linkedin.com/sharing/share-offsite/?url=",
  "subsidiary_reduced_logo": {
    "fr": {
      "path": "/102759/1724158503-logo_k_bleu-size5.svg",
      "format": "svg",
      "size": 754,
      "alt": "Logo Keolis",
      "title": "Keolis",
      "width": 256,
      "height": 256
    }
  },
  "home_page_title": {
    "fr": "Accueil"
  },
  "mailto_addresse": "mailto:someone@example.com",
  "share_buttons_title": {
    "fr": "Partager"
  },
  "transport_mode_title": {
    "fr": "Modes de transport"
  },
  "facebook_label": {
    "fr": "Facebook"
  },
  "token_google_site_verification": "",
  "twitter_label": {
    "fr": "Twitter"
  },
  "linkedin_label": {
    "fr": "LinkedIn"
  },
  "mailto_label": {
    "fr": "Partager par mail"
  },
  "download_label": {
    "fr": "Télécharger"
  },
  "updated_at": "2024-08-20T14:55:21.763+02:00",
  "created_at": "2021-04-20T17:40:04.593+02:00",
  "locale": "fr",
  "locales": [
    "fr"
  ],
  "siteUrl": "https://www.keolis-idf.com/"
})