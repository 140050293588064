import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import {
  NewsMediaFieldsFragment,
  NewsroomFilterTitlesFieldsFragment,
  PressReleaseMediaFieldsFragment,
  PublicationMediaFieldsFragment,
} from "../../../../graphql-types"
import * as styles from "./media-cards.module.scss"
import sanitizeHtml from "sanitize-html"
import { useSlugs } from "../../context/slugs-context"
import { useAssociatedColor, useIsDateInRange } from "../../hooks"
import ScrollArrow from "../tools/scroll-arrow/scroll-arrow"
import Card from "./card/card"
import Capsule from "../tools/filter-bar/capsule/capsule"
import FilterBar from "../tools/filter-bar/filter-bar"

type RenderProps = {
  title: string
  text?: string
  buttonLink?: string
  buttonLabel?: string
  news?: NewsMediaFieldsFragment[]
  pressReleases?: PressReleaseMediaFieldsFragment[]
  publications?: PublicationMediaFieldsFragment[]
  filters?: NewsroomFilterTitlesFieldsFragment
  categories?: string[]
  limit?: number
  multiRow?: boolean
}

const MediaCards: React.FC<RenderProps> = ({
  title,
  text,
  buttonLink,
  buttonLabel,
  news,
  pressReleases,
  publications,
  filters,
  categories,
  limit,
  multiRow,
}) => {
  const { buildInternalLink } = useSlugs()
  const mediaCardsRef = useRef(null)
  const cards = [...(pressReleases ?? []), ...(publications ?? []), ...(news ?? [])]
    .filter(card => useIsDateInRange(card.publicationDate, card.publicationEndDate))
    .sort((a, b) => new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime())
  const [filteredCards, setFilteredCards] = useState<any[]>(cards)
  const [activeCapsule, setActiveCapsule] = useState<string>("")

  const scrollToCard = (scrollDirection: number) => {
    const rtlRatio = document?.dir === "rtl" ? -1 : 1
    const itemWidth = mediaCardsRef.current.children[1].clientWidth + 32
    mediaCardsRef.current.scrollBy({
      left: itemWidth * scrollDirection * rtlRatio,
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    if (mediaCardsRef.current) {
      mediaCardsRef.current.scrollLeft = 0
    }
  }, [cards])

  const sanitizedText = sanitizeHtml(text)

  const filtersArray: { title: string; typename: string; filterPublications?: boolean }[] = !filters
    ? !categories
      ? []
      : categories.map(category => ({ title: category, typename: "DatoCmsPublication", filterPublications: true }))
    : [
        { title: filters.newsroomInTheSpotlightIsEnabled && filters.newsroomInTheSpotlightTitle, typename: "" },
        { title: filters.newsroomNewsIsEnabled && filters.newsroomNewsTitle, typename: "DatoCmsNews" },
        {
          title: filters.newsroomPressReleaseIsEnabled && filters.newsroomPressReleaseTitle,
          typename: "DatoCmsPressRelease",
        },
        {
          title: filters.newsroomPublicationsIsEnabled && filters.newsroomPublicationsTitle,
          typename: "DatoCmsPublication",
        },
      ].filter(filter => filter.title)

  return (
    <div className={styles.mediaContainer}>
      <div className={styles.header}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={styles.content}>
          {(filtersArray || sanitizedText) && (
            <div className={styles.filtersColumn}>
              {filtersArray.length > 0 && (
                <FilterBar
                  capsules={filtersArray.map(filter => ({
                    label: filter.title,
                    onClick: () => {
                      if (activeCapsule !== filter.title) {
                        if (filter.filterPublications) {
                          setFilteredCards(
                            cards.filter(
                              card =>
                                card.__typename === "DatoCmsPublication" && card.category?.category === filter.title
                            )
                          )
                        } else {
                          setFilteredCards(
                            filter.typename ? cards.filter(card => card.__typename === filter.typename) : cards
                          )
                        }
                        setActiveCapsule(filter.title)
                      } else {
                        setFilteredCards(cards)
                        setActiveCapsule("")
                      }
                    },
                    isActive: activeCapsule === filter.title,
                  }))}
                />
              )}
              {sanitizedText && <p className={styles.textIntro} dangerouslySetInnerHTML={{ __html: sanitizedText }} />}
            </div>
          )}
          {buttonLabel && (
            <a href={buttonLink} className={styles.capsule}>
              <Capsule title={buttonLabel} isSelected={false} special={true} />
            </a>
          )}
        </div>
      </div>
      <ul className={styles.cardsContainer} ref={mediaCardsRef} style={{ flexWrap: multiRow ? "wrap" : "nowrap" }}>
        {filteredCards?.slice(0, limit).map((card, index) => {
          if (card.__typename === "DatoCmsPublication") {
            return (
              <Card
                key={card.id}
                title=""
                url={card.isPdf ? card.pdf?.url : card.externalLink}
                cardType="image"
                date=""
                backgroundSvg=""
                backgroundImage={card.image}
                backgroundColor=""
                backgroundColorBis=""
                textColor=""
              />
            )
          }

          let backgroundImage = { url: "", alt: "" }
          let backgroundSvg = ""
          let cardType = "svg"
          const title = card.miniatureCard[0]?.isTitle === false ? "" : card.title

          if (card.miniatureCard[0]?.isImage && card.miniatureCard[0]?.miniatureImage?.url) {
            backgroundImage = {
              url: card.miniatureCard[0]?.miniatureImage?.url || "",
              alt: card.miniatureCard[0]?.miniatureImage?.alt || "",
            }
            card.miniatureCard[0]?.isMinatureImageBig ? (cardType = "image") : (cardType = "split")
          }
          if (!card.miniatureCard[0]?.isImage || !card.miniatureCard[0]?.isMinatureImageBig) {
            backgroundSvg = `/vectors/v3/mediaCards/card${(index % 4) + 1}.svg`
          }
          const getCardUrl = card => {
            if (card.__typename === "DatoCmsNews") {
              return buildInternalLink({ __typename: "DatoCmsNews", slug: card.slug })
            } else if (card.__typename === "DatoCmsPressRelease") {
              return buildInternalLink({ __typename: "DatoCmsPressRelease", slug: card.slug })
            }
          }

          return (
            <Card
              key={card.id}
              title={title}
              url={getCardUrl(card)}
              cardType={cardType}
              date={card.publicationDate}
              backgroundSvg={backgroundSvg}
              backgroundImage={backgroundImage}
              backgroundColor={card.miniatureCard[0]?.cardBackgroundColor.hex}
              backgroundColorBis={useAssociatedColor(card.miniatureCard[0]?.cardBackgroundColor.hex)}
              textColor={card.miniatureCard[0]?.cardTextColor.hex}
            />
          )
        })}
      </ul>

      {limit > 4 && (
        <div className={styles.arrowsContainer}>
          <ScrollArrow direction="left" onClick={() => scrollToCard(-1)} />
          <ScrollArrow direction="right" onClick={() => scrollToCard(1)} />
        </div>
      )}
    </div>
  )
}

export const fragment = graphql`
  fragment MediaNewsFields on DatoCmsHomePageMediaNews {
    isEnabled
    title
    text
    gradientBackground
    buttonLabel
  }

  fragment MediaMiniatureCardFields on DatoCmsMediaMiniatureCard {
    isTitle
    cardBackgroundColor {
      hex
    }
    cardTextColor {
      hex
    }
    isImage
    miniatureImage {
      alt
      url
    }
    isMinatureImageBig
  }

  fragment NewsMediaFields on DatoCmsNews {
    id
    __typename
    title
    slug
    publicationDate
    publicationEndDate
    miniatureCard {
      ...MediaMiniatureCardFields
    }
  }

  fragment PressReleaseMediaFields on DatoCmsPressRelease {
    id
    __typename
    title
    slug
    publicationDate
    publicationEndDate
    miniatureCard {
      ...MediaMiniatureCardFields
    }
  }

  fragment PublicationMediaFields on DatoCmsPublication {
    id
    __typename
    title
    image {
      alt
      url
    }
    category {
      category
    }
    isPdf
    pdf {
      url
    }
    externalLink
    publicationDate
    publicationEndDate
  }

  fragment NewsroomFilterTitlesFields on DatoCmsNewsroom {
    newsroomInTheSpotlightIsEnabled
    newsroomInTheSpotlightTitle
    newsroomNewsIsEnabled
    newsroomNewsTitle
    newsroomPressReleaseIsEnabled
    newsroomPressReleaseTitle
    newsroomPublicationsIsEnabled
    newsroomPublicationsTitle
  }
`

export default MediaCards
