import React from "react"
import * as styles from "./banner.module.scss"

type RenderProps = {
  title: string
  image?: {
    alt?: string
    url?: string
  }
  isFullscreenImage?: boolean
}

const Banner: React.FC<RenderProps> = ({ title, image, isFullscreenImage }) => {
  return (
    <div className={styles.banner}>
      <div className={`${styles.container} ${isFullscreenImage ? styles.fullscreenBanner : ""}`}>
        <h1 className={`${styles.title} ${isFullscreenImage ? styles.overlayTitle : ""}`}>{title}</h1>
        {image?.url && (
          <img
            src={image.url}
            alt={image.alt || ""}
            className={`${styles.image} ${isFullscreenImage ? styles.fullscreenImage : ""}`}
          />
        )}
      </div>
    </div>
  )
}

export default Banner
