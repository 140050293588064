import React, { useRef } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { V3BlockToPdfFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./v3-block-to-pdf.module.scss"
import { useAssociatedColor, useIsMobile } from "../../../hooks"
import ScrollArrow from "../../tools/scroll-arrow/scroll-arrow"

type RenderProps = {
  data: V3BlockToPdfFieldsFragment
}

const V3BlockToPdf: React.FC<RenderProps> = ({ data }) => {
  const isMobile = useIsMobile()
  const cards = useRef(null)
  const scrollToCard = (scrollDirection: number) => {
    const itemWidth = cards.current.children[0].clientWidth + 4
    cards.current.scrollBy({
      left: itemWidth * scrollDirection,
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.emptyLeftPart} />
      <div className={styles.alignRight}>
        <div className={styles.cardContainer} ref={cards}>
          {data.listOfPdf.map((pdf, index) => (
            <a href={pdf.pdf.url} target="_blank" className={styles.card} key={index}>
              <ReactSVG
                src={`/vectors/v3/block-to-pdf/card${(index % 4) + 1}.svg`}
                className={styles.backgroundSvg}
                afterInjection={svg => {
                  svg
                    .querySelectorAll("#Groupe_1640 rect, #Groupe_1640 path")
                    .forEach(group => group.setAttribute("style", `fill: ${pdf.backgroundColor.hex}`))
                  svg
                    .querySelector("#BK-DARK")
                    .setAttribute("style", `fill: ${useAssociatedColor(pdf.backgroundColor.hex)}`)
                }}
              />

              <ReactSVG src={"/vectors/v3/block-to-pdf/open-link.svg"} className={styles.openLink} />
              <div className={styles.title} style={{ color: pdf.titleColor.hex }}>
                {pdf.title}
              </div>
            </a>
          ))}
        </div>
        {!isMobile && data.listOfPdf.length > 4 && (
          <div className={styles.arrowsContainer}>
            <ScrollArrow direction="left" onClick={() => scrollToCard(-1)} />
            <ScrollArrow direction="right" onClick={() => scrollToCard(1)} />
          </div>
        )}
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment V3BlockToPdfFields on DatoCmsV3BlockToPdf {
    __typename
    listOfPdf {
      title
      titleColor {
        hex
      }
      backgroundColor {
        hex
      }
      pdf {
        url
      }
    }
  }
`

export default V3BlockToPdf
